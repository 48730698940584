/*
 * @file
 * Defines the general structure of site pages.
 *
 * Primary content and tertiary sections have restricted widths for legibility.
 * Secondary and Quaternary sections are set to the maximum width ($max_width) for impact.
 *
 * The splash area is the full width of the viewport
 *
 */

main {
  @include set-bottom-margin(1); }


#primary-content, #tertiary-content {
  @extend %legibility-inset; }

body.front {
  #primary-content {
    @extend %remove-inset; } }
