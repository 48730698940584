.field-name-field-subtitle {
  text-align: center;
  margin: 0;
  color: $dark_neutral;

  .field-item {
    @include font-size(0);
    font-weight: $semiboldfw;
    font-family: $altfont; } }

