table {
  @extend %data-table;

  td, td a {
    color: $dark_neutral; }

  tbody {
    tr {
      &:hover {
        background-color: set-color(2,'fade');

        td, td a {
          color: $black; }

        a {
          text-decoration: underline; } } }
    td {
      vertical-align: top; } } }
