figure {
  position: relative; }

figcaption {
  position: absolute;
  width: 100%;
  bottom: 0;
  font-style: normal;
  text-align: center;
  background-color: $light_neutral;
  border-top: 5px solid $white;
  @include set-padding(0);
  display: none;

  h4 {
    margin: 0; }

  *:last-child {
    margin-bottom: 0; } }
