.views-exposed-form {
  background-color: set-colour(2,'watermark');
  @include set-padding(-1);
  @include set-bottom-margin(1);

  .views-exposed-widgets {
    @extend %local-grid-three;
    grid-row-gap: rv(-4); }

  input {
    width: 100%; } }
