// Font size by scale factor

@mixin font-size($factor: 0,$ratio: $scale) {
  font-size: ms($factor,$basetypesize,$ratio); }

// Set notation

@mixin set-font-size($factor: 0,$ratio: $scale) {
  @include font-size($factor,$ratio); }

// Unscaled font-size

@mixin base-font-size() {
  font-size: $basetypesize; }

// Alias of above

@mixin base-type-size() {
  @include base-font-size(); }
