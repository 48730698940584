.field-name-field-deck {
  @extend %deck; }


.field-name-field-deck + .field-name-body {
  border-top: 1px solid $mid_neutral;
  @include set-top-padding(4); }

div.node .field.field-name-field-deck {
  margin-bottom: 0; }
