.reftype-document {
  a {
    &:before {
      @extend %icon-inline;
      background-image: url("#{$iconpath}/document-rev.svg");
      display: inline-block; } } }

.reftype-person {
  a {
    &:before {
      @extend %icon-inline;
      background-image: url("#{$iconpath}/profile-rev.svg");
      background-size: rv(-4); } } } // set for individual icon
