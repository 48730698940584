// Narrows the content column width to shorten line lengths and improve legibility.

%legibility-inset {
  @include inset-text(); }

%full-width {
  @include full-width-text(); }

// Alias for clarity

%remove-inset {
  @extend %full-width; }
