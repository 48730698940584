/*
 * Defines a common set of rules for form elements.
 * Definitions are set in a_core/10_Definitions/_forms.sass
 */

// .trigger is a Drupal class for contextual menus.

.btn {
  @extend %btn; }

.btn-submit {
  @extend %btn-submit; }

input {
  &[type='button'] {
    @extend %btn; }

  &[type='submit'] {
    @extend %btn-submit;
    @include set-margin-top(0); }

  &[type='reset'] {
    @extend %btn-reset; }

  &[type='text'] {
    @extend %text-element-base; }

  &[type='search'] {
    @extend %text-element-base; }

  &[type='url'], &[type='date'], &[type='email'], &[type='password'] {
    @extend %text-element-base; } }

select {
  @extend %select-element-base; }

textarea {
  @extend %textarea-base;

  @extend %form-element-hover; }

.form-item {
  @include set-margin-top(0);

  &:first-child {
    margin-top: 0; } }

