/* ! Sticky Header Class */

.sticky {
  position: fixed;
  top: 0; }

.sticky-mobile {
  position: fixed;
  top: 0;

  @include bp(med) {
    position: initial;
    top: initial; } }
