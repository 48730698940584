/*
 * Defines a common set of rules for form elements.
 * Overrides browser default form element styling.
 * Values are set in the configuration file.
 */


%form-element-hover {
  &:hover, &:focus, &:active {
    text-decoration: none;
    border-color: $ui_hover_border_color;
    box-shadow: inset 0 0 0 $ui_border_width $ui_hover_border_color;
    transition: all 0.2s; } }

%form-element-base {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  vertical-align: baseline;
  box-sizing: border-box;
  zoom: 1;
  font-family: $ui_font_family;
  @include set-padding($ui_padding,0);
  @include font-size($ui_font_size);
  line-height: $blh;
  font-weight: $normalfw;
  vertical-align: middle;
  border-style: solid;
  border-width: $ui_border_width;
  border-color: $ui_border_color;
  border-radius: $ui_border_radius;
  background-clip: border-box;
  box-shadow: none;
  outline: none;
  transition: all 0.2s;
  max-width: 100%;

  @extend %form-element-hover; }

%text-element-base {
  @extend %form-element-base;
  appearance: none;

  @if $ui_element_height != auto {
    height: $ui_element_height;
    min-height: $ui_element_height; } }

%textarea-base {
  box-sizing: border-box;
  max-width: 100%;
  @include set-padding($ui_padding,0);
  box-shadow: none;
  border-style: solid;
  border-width: $ui_border_width;
  border-color: $ui_border_color;
  border-radius: $ui_border_radius;
  background-clip: border-box;
  transition: all 0.2s; }

%select-element-base {
  @extend %text-element-base; }

/*
 * A note on element height
 * Button base styling includes a logic intended to enforce consistent height between form inputs and buttons.
 * This is particularly important for inline forms, where select, input and submit buttons must be consistent.
 *
 * The best approach is to enforce a $ui_element_height, and ensure that it's larger than the default button height.
 * The fallback is a logic which takes into account ui elements border widths in the instances where text input
 * has borders and buttons do not.
 *
 */

$btnPadding: ms($btn_padding,$blh,$scale);
$btnBorderAllowance: $ui_border_width;


%form-description {
  @include font-size(-1);
  color: $dark_neutral; }

%btn-base {
  @extend %form-element-base;

  @if $ui_element_height != auto {
    height: $ui_element_height;
    flex-shrink: 0; }

  @if $buttonfontsize != $ui_font_size {
    @include font-size($buttonfontsize); }

  @if $btn_border_width != $ui_border_width {
    border-width: $btn_border_width;
    padding: calc(#{$btnPadding} + #{$btnBorderAllowance}) $btnPadding; }
  @else {
    @if $btn_padding != $ui_padding {
      @include set-padding($ui_padding,0); } }

  @if $btn_border_color != $ui_border_color {
    border-color: $btn_border_color; }

  @if $btn_border_radius != $ui_border_radius {
    border-radius: $btn_border_radius; }

  justify-content: center;
  text-align: center;
  background-clip: border-box;
  cursor: pointer;
  min-width: $btn_min_width;
  width: $btn_width;
  max-width: $btn_max_width;
  transition: all 0.3s;
  white-space: nowrap;

  &:hover, &:focus, &:active {
    text-decoration: none;
    box-shadow: none;
    @extend %standard-transition; } }

%btn {
  @extend %btn-base;
  color: $btn_color;
  background-color: $btn_bg_color;
  border: $btn_border_width solid $btn_border_color;
  &:hover, &:focus, &:active {
    background-color: $btn_hover_bg_color;
    color: $btn_hover_color;
    border: $btn_border_width solid $btn_hover_border_color; } }

%btn-submit {
  @extend %btn-base;
  color: $btn_submit_color;
  background-color: $btn_submit_bg_color;
  border: $btn_border_width solid $btn_submit_border_color;
  &:hover, &:focus, &:active {
    background-color: $btn_submit_hover_bg_color;
    color: $btn_submit_hover_color;
    border: $btn_border_width solid $btn_submit_hover_border_color; } }

%btn-reset {
  @extend %btn-base;
  color: $dark_neutral;
  background-color: $white;
  border: $btn_border_width solid $dark_neutral;
  &:hover, &:focus, &:active {
    background-color: $dark_neutral;
    color: $btn_hover_color; } }

%btn-inverted {
  @extend %btn-base;
  color: $btn_bg_color;
  background-color: $btn_color;
  border: $btn_border_width solid $btn_bg_color;
  &:hover, &:focus, &:active {
    background-color: $btn_hover_color;
    color: $btn_color; } }

%btn-outline {
  @extend %btn-base;
  color: $outline_btn_color;
  background-color: transparent;

  @if $btn_border_width == 0 {
    border: 1px solid $outline_btn_color; }
  @else {
    border: $btn_border_width solid $outline_btn_color; }

  &:hover, &:focus, &:active {
    @if $btn_border_width == 0 {
      border: 1px solid $outline_btn_hover_color; }
    @else {
      border: $btn_border_width solid $outline_btn_hover_color; }
    color: $outline_btn_hover_color; } }

%btn-ghost {
  @extend %btn-base;
  color: $white;
  background-color: transparent;

  @if $btn_border_width == 0 {
    border: 1px solid $white; }
  @else {
    border: $btn_border_width solid $white; }

  &:hover, &:focus, &:active {
    @if $btn_border_width == 0 {
      border: 1px solid $white; }
    @else {
      border: $btn_border_width solid $white; }
    background-color: darken($btn_hover_color,5%);
    color: $white; } }



