#site-header {
  width: 100%;
  text-align: center;
  @include set-top-margin(5);
  @include set-bottom-margin(4);

  .logo {
    display: inline-block;
    width: 100%;
    max-width: 450px; } }

/* MAIN MENU */

// Additional homepage menu styles located in ./40_components/view/_homepage_splash

#block-system-main-menu {
  overflow: visible;

  .block_content {

    // Top Level menu
    > ul.menu {
      @include set-top-margin(-2);
      position: relative;

      // Background for secondary menu

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        bottom: rv(2) * -1;
        height: rv(1);
        background-color: $light_neutral;
        @include responsive-inset();
        opacity: 0;
        transition: opacity 0.5s ease; }

      &.hover {
        &:after {
          opacity: 1;
          transition: opacity 0.5s ease; } } }

    // Top and nested menus

    ul.menu {
      display: flex;
      flex-grow: 0;
      flex-shrink: 1;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      padding: 0;
      overflow: visible;
      padding: 0;

      a {
        &:hover, &.active {
          color: $impact; } }

      > li {
        display: block;
        transition: all 0.5s ease;
        list-style-type: none;
        margin-left: 0;
        position: relative;
        @include set-left-margin(-5);
        @include set-right-margin(-5);

        // Secondary Menu

        > ul.menu {
          width: $inset_text_width;
          position: absolute;
          transition: all 0.5s ease;
          margin-left: 0;
          @include set-top-margin(-10);
          left: 0;
          justify-content: flex-start;
          opacity: 0;
          z-index: 2;

          li {
            margin-left: 0;

            // Hide third and deeper menus.

            ul.menu {
              display: none; } } }

        &:hover {
          > ul.menu {
            opacity: 1; } } } } } }

// Hide main menu on homepage

body.front {
  #block-system-main-menu {
    > ul.menu {
      display: none; } } }

