ul.primary {
  border-top: 2px solid $light_neutral;

  li {
    a {
      color: $neutral;

      &:after {
        --mfactor: 1rem * -1;
        bottom: initial;
        top: calc(var(--mfactor) - 2px); } } } }

.task-nav {
  position: absolute;
  z-index: 2;
  top: 65vh;
  width: 100%;
  pointer-events: none;

  a {
    pointer-events: all;
    box-sizing: border-box;
    max-width: rv(12);
    width: rv(5);
    height: rv(5);
    color: $white;
    background-color: $darker_neutral;
    font-family: $altfont;
    font-weight: $boldfw;
    @include font-size(1);
    @include set-padding(-2);
    display: none;

    &:hover {
      background-color: $black;
      text-decoration: none; } }

  .next-task {
    float: right;
    text-align: left;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 40px;
      background-repeat: no-repeat;
      background-image: url("#{$iconpath}/arrow.svg");
      background-position: right center;
      transform: rotate(180deg);
      left: -50px;
      top: 0;
      opacity: 0;
      transition: opacity 0.2s ease-in; }

    &:hover {
      &:before {
        opacity: 1;
        transition: opacity 0.2s ease-out; } } }

  .prev-task {
    float: left;
    text-align: right;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 40px;
      background-repeat: no-repeat;
      background-image: url("#{$iconpath}/arrow.svg");
      background-position: left center;
      right: -50px;
      top: 0;
      opacity: 0;
      transition: opacity 0.3s ease-in; }

    &:hover {
      &:before {
        opacity: 1;
        transition: opacity 0.3s ease-out; } } } }
