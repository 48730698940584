/* Handles the document viewer. Not to be confused with ../_view_document, which handles the Document page table */

$dvcolpadding: rv(0) * 2;

.view-id-document_viewer {

  .accordion-ui {
    height: 30px;
    width: 30px;
    display: block;
    position: absolute;
    top: rv(0);
    right: rv(0); }

  .viewer {
    .field-content {
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      align-items: stretch;
      flex-wrap: nowrap;
      background-color: $lightest_neutral;
      min-height: 450px;

      > * {
        flex-grow: 0;
        width: 100%;
        @include set-padding(0);
        border-color: $white;
        border-style: solid;
        border-width: rv(-10) * 2 rv(-10);
        position: relative;
        min-width: calc( #{$dvcolpadding} + 34px );

        &:first-child {
          border-left: 0; }
        &:last-child {
          border-right: 0; }

        > .views-label {
          @include set-bottom-margin(2); }

        &.panel-closed {
          background-color: $neutral;
          text-align: center;

          .accordion-ui {
            display: inline-block;
            position: relative;
            right: auto;
            top: auto; }

          &:after {
            opacity: 1;
            transition: opacity 0.3s;
            transition-delay: 0.3s; }


          &.original-image:after {
            content: 'Image'; }
          &.transcription:after {
            content: 'Transcription'; }
          &.translation:after {
            content: 'Translation'; }

          > * {
            display: inline-block; } }


        &:after {
          content: '';
          position: relative;
          display: inline-block;
          top: rv(2);
          text-transform: uppercase;
          font-weight: $boldfw;
          color: $dark_neutral;
          writing-mode: vertical-lr;
          text-orientation: upright;
          opacity: 0;
          transition: opacity 0.3s;
          transition-delay: 0.3s; } } } }


  .views-field-field-doc-notes {
    background-color: $lightest_neutral;
    @include set-padding(0); }

  .views-field-field-docpg-feedback {
    justify-self: flex-end; }

  .viewer-text {
    @extend %body-text; }

  .views-row {
    > header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; } } }
