p, address {
  @extend %body-text; }

dl {
  @include set-bottom-margin(0); }

dt {
  font-family: $headingfont;
  font-weight: $boldfw;
  text-transform: uppercase; }

dd {
  @include set-bottom-margin(-1);
  margin-left: 0; }

menu, dir {
  margin: 0;
  padding: 0; }

q {
  quotes: none; }

abbr[title] {
  border-bottom: 1px dotted $dark_neutral;
  cursor: help; }

b, strong, .strong {
  font-weight: $semiboldfw; }

dfn, .italic {
  font-style: italic; }

ins {
  background-color: $light_neutral;
  color: $black;
  text-decoration: none; }

mark {
  background-color:  $light_neutral;
  color: $black;
  font-style: italic;
  font-weight: $boldfw; }

pre, code, kbd, samp {
  font-family: $codefont;
  @include font-size(0);
  @include line-height(0);
  color: $darker_neutral; }

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

sub, sup {
  position: relative;
  @include font-size(-1);
  line-height: 0;
  vertical-align: baselineheight; }

sup {
  top: ms(-2,$basetypesize,$scale) * -0.5; }

sub {
  bottom: ms(-2,$basetypesize,$scale) * -0.25; }

.spaced {
  letter-spacing: 1px; }

hr {
  border: 0;
  border-bottom: 1px solid $dark_neutral;
  @include spacing('margin-top',2);
  @include spacing('margin-bottom',2); }
