label, .views-label {
  text-transform: uppercase;
  font-weight: $boldfw; }

.more {
  display: block;
  @include set-top-margin(1);
  font-family: $basefont;
  text-transform: uppercase;
  @include font-size(-1);
  color: $mid_neutral;

  &:hover {
    color: $black; } }

.alias {
  font-style: italic;

  // Correcting an oddity

  div {
    display: inline;

    &:before {
      content: ' '; } } }

.deck {
  @extend %deck; }

.feed-icon {
  width: 50px; }

.surtitle {
  @extend %surtitle;

  a {
    color: set-colour(1); } }

// Aliases

.inset-text, .legibility-inset {
  @extend %legibility-inset; }

.remove-inset {
  @extend %remove-inset; }
