%deck {
  @include font-size(1);
  @include line-height(0);
  font-family: $altfont;
  font-style: italic;
  text-align: center;
  @include inset-text(0);
  @include set-bottom-padding(3);

  @include bp(med) {
    @include line-height(1); }

  @include bp(lrg) {
    @include line-height(2); }

  * {
    font-size: inherit;
    line-height: inherit; } }
