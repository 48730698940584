%local-grid-five {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-column-gap: rv(0);
  grid-row-gap: rv(0);

  @include bp(lrg) {
    grid-template-columns: repeat(3,1fr); }

  @include bp(xlrg) {
    grid-template-columns: repeat(5,1fr); } }


%local-grid-four {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-column-gap: rv(0);
  grid-row-gap: rv(0);

  @include bp(xlrg) {
    grid-template-columns: repeat(4,1fr); } }


%local-grid-three {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-column-gap: rv(0);
  grid-row-gap: rv(0);

  @include bp(xlrg) {
    grid-template-columns: repeat(3,1fr); } }
