.chosen-container {
  @include font-size(1);
  font-family: $basefont; }

.chosen-container.form-select {
  .chosen-single {
    @extend %select-element-base;
    background-image: none;
    background-color: $white; } }

.chosen-container-multi .chosen-choices li.search-field {
  input[type="text"] {
    @extend %text-element-base; } }

.chosen-container-multi .chosen-choices li.search-field input[type="text"]:active {
  border-color: transparent;
  box-shadow: none;
  border-width: 0;
  border-image-width: 0; }

.chosen-container-multi .chosen-choices {
  @extend %text-element-base;
  background-image: none;

  li {
    width: 100%; } }

// Fixes overflow problem on many Chosen forms.

section.block-views {
  overflow: visible; }
