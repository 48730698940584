.tl-timeline {
  font-family: $basefont;

  // Base

  .tl-text {

    h1, h2, h3, h4, h5, h6 {
      margin: 0;
      color: $black;
      font-family: $headingfont;
      font-weight: $headingfw; }

    h1 {
      @include font-size(map-get($sizefactors,'h1'));
      @include line-height(0);
      @include set-top-margin(1);
      @include set-bottom-margin(2,0); }

    h2 {
      @include font-size(map-get($sizefactors,'h2'));
      @include line-height(1);
      @include set-top-margin(1,0);
      @include set-bottom-margin(1,0); }

    h3 {
      @include font-size(map-get($sizefactors,'h3'));
      @include line-height(1);
      @include standard-top-margin();
      margin-bottom: 0;
      text-transform: uppercase; }

    h4 {
      @include font-size(map-get($sizefactors,'h4'));
      @include line-height(1);
      @include standard-top-margin();
      margin-bottom: 0; }

    h5 {
      @include font-size(map-get($sizefactors,'h5'));
      @include line-height(1);
      @include set-top-margin(0,0);
      font-weight: $normalfw;
      font-style: italic;
      margin-bottom: 0; }

    h6 {
      @include font-size(map-get($sizefactors,'h6'));
      @include line-height(1);
      @include set-top-margin(0,0);
      margin-bottom: 0;
      font-weight: $normalfw;
      color: $dark_neutral; }

    // Local

    h2, h2.tl-headline-title, h2.tl-headine {
      font-family: $altfont;
      font-weight: $boldfw;

      a {
        color: $black; } }

    h3, h3.tl-headline-date {
      font-weight: $normalfw;
      color: set-color(1); }

    h4 {
      font-family: $altfont;
      font-weight: $normalfw; }

    h5 {
      font-family: $basefont;
      font-weight: $boldfw;
      text-transform: uppercase; }

    h6 {
      font-family: $basefont;
      font-weight: $boldfw;
      text-transform: initial;
      color: $black; }

    .subtitle {
      font-family: $basefont;
      font-style: italic;
      @include font-size(map-get($sizefactors,'h3'));
      @include line-height(map-get($sizefactors,'h3')); }

    h1 + .subtitle {
      @include font-size(map-get($sizefactors,'h2'));
      @include line-height(map-get($sizefactors,'h2')); } }

  .tl-slide {
    background-color: set-colour(1,'watermark'); }

  .tl-timenav .tl-timenav-slider .tl-timenav-slider-background {
    background-color: $lightest_neutral; }

  // Base

  p, ol, ul, th, td, dl, dd, dt, address, div, section, article, aside {
    @include font-size(map-get($sizefactors,'base'));
    @include standard-line-height();
    font-weight: $normalfw; } }
