// Defines the base type scale system.
// Definitions for type elements located in 30_elements

html {
  font-family: $basefont;
  font-size: ms(map-get($typescale,'base'),$rootsize,$scale);
  color: $black;

  @include bp(xsml) {
    font-size: ms(map-get($typescale,'xsml'),$rootsize,$scale); }

  @include bp(sml) {
    font-size: ms(map-get($typescale,'sml'),$rootsize,$scale); }

  @include bp(med) {
    font-size: ms(map-get($typescale,'med'),$rootsize,$scale); }

  @include bp(lrg) {
    font-size: ms(map-get($typescale,'lrg'),$rootsize,$scale); }

  @include bp(xlrg) {
    font-size: ms(map-get($typescale,'xlrg'),$rootsize,$scale); }

  @include bp(xxlrg) {
    font-size: ms(map-get($typescale,'xxlrg'),$rootsize,$scale); } }


p, ol, ul, th, td, dl, dd, dt, address, div, section, article, aside {
  @include font-size(map-get($sizefactors,'base'));
  @include standard-line-height();
  letter-spacing: $basetypesize * 0.04;
  font-weight: $normalfw; }

