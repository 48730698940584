#page-footer {
  font-family: $altfont;
  background-color: $black;
  color: $white;

  @include set-top-padding(0);
  @include set-bottom-padding(0);

  h2, a, li {
    color: $white; }

  > * {
    @include responsive-inset();
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    @include bp(lrg) {
      flex-direction: row; }

    > * {
      @include set-bottom-margin(1);

      @include bp(lrg) {
        margin-bottom(0) {} } } }

  .marks {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    a {
      display: block;
      @include set-right-padding(2); }

    svg {
      stroke: $white;
      fill: $white; }

    #lsc-mark {
      svg {
        height: rv(8); } }

    #berkeley-mark {
      svg {
        height: rv(5); } } }


  .footer {
    #block-system-user-menu, #block-menu-menu-footer-menu {
      h2 {
        display: none; }

      ul.menu {
        list-style-type: none;
        padding-left: 0;

        li {
          text-transform: none;
          font-family: $basefont;
          margin: 0;
          display: inline-block;
          font-weight: $semiboldfw;

          a {
            text-decoration: underline; } } } }


    @include bp(lrg) {
      width: 30%;
      text-align: right;

      #block-system-user-menu, #block-menu-menu-footer-menu {
        ul.menu {
          text-align: right;

          li {
            padding: 0 0 0 rv(0); } } } } }






  h2 {
    @include font-size(0);
    @include line-height(-3);
    @include set-bottom-margin(-5);
    text-transform: uppercase;
    font-family: $basefont; } }
