.views-eva-document-text-and-image-compact {

  .views-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: flex-start;

    @include bp(med) {
      flex-wrap: nowrap; }

    > * {
      width: 48%; }

    .views-field-field-docpg-img {
      background-color: set-colour(2,'fade');
      @include set-padding(0); }

    .transcription {
      @extend %body-text; } } }
