/**
 * @file
 * Base Theme Configuration File
 * See _configuration_instructions.txt for help.
 */


/* !APPLICATION */

// Configure the application in style.sass

/* !CORE VALUES */

$media_type: screen;
$feature: min-width;
$max_width: 1400px;

// Used to define a standard proportion across the site as per the modularscale library
// See ./a_core/10_libraries/10_vendor/modularscale/modularscale/_vars.scss for $scale references

$scale: 1.2;
$standard_sidebar_width: 100% - (100/$scale);

$masthead_height: 40px;
$admin_bar_height: 48px;

$min_vis_height: 800px;


/* !COLOUR GRID

  Define your design’s palette by choosing the main colours

  Colours can be accessed via set-colour() function, which takes the colour number and optional deriavive,
  e.g. set-colour(1,shade) */

$clr1: #AD6A54; // Brick
$clr2: #767059; // Parchment. Use “fade” value in most circumstances
$clr3: #507EA4; // Blue
$clr4: #6B8F80; // Green
$clr5: #111111;
$clr6: #000000;

// Map your colours to five luminesce levels.
// 9 maps are included to support the set-colours function. Leave unused maps empty

$clr1_map: (shade: #734738, primary: $clr1, tint: #D9C0B8, fade: #FAE9E3, watermark: #FCFAF9);  // Brick
$clr2_map: (shade: #3B3724, primary: $clr2, tint: #D9D2B8, fade: #F2EDDA, watermark: #FAF8F1);  // Parchment
$clr3_map: (shade: #335775, primary: $clr3, tint: #B8CAD9, fade: #DCE8F2, watermark: #F8FAFB);  // Blue
$clr4_map: (shade: #506B60, primary: $clr4, tint: #BCD9CC, fade: #DAF0E6, watermark: #F2F5F4);  // Green
$clr5_map: ();
$clr6_map: ();
$clr7_map: ();
$clr8_map: ();
$clr9_map: ();

$black: #3B3724;
$white: #FEFEFC;
$neutral: #CCCBC7;

$light_neutral: #EBEAE4;
$lighter_neutral: mix($white,$light_neutral,5%);
$lightest_neutral: mix($white,$light_neutral,10%);

$mid_neutral: #ABAAA6;
$dark_neutral: darken($mid_neutral,20%);
$darker_neutral: darken($mid_neutral,40%);

// Aliases

$impact: $clr1;
$cta: $clr3;
$de_emphasized: $light_neutral;
$de_activated: $lighter_neutral;
$link_colour: $black;

$yellow: $clr1;
$blue: $clr1;
$green: $clr4;
$blue: $clr3;


// Define alternative blackpoint, whitepoint and neutrals.

// Define the colour of the page background

$bgcolor: $white;

/* !BREAKPOINTS */


/*  Values for your responsive design breakpoints. This has now been rethought
    thanks to https://medium.freecodecamp.com/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862#.rduwqht4g

  360px 	  22.5em     xsml   iphone6/7/8
  600px 	  37.5em     sml
  760px 	  47.5em     med    ipad
  1120px    70em       lrg    laptop screen
  1400px	  87.5em     xlrg   desktop screen, above max width
  1800px    112.5em    xxlrg  large monitor */

$breakpoints: (xsml: 22.5em, sml: 37.5em, med: 47.5em, lrg: 70em, xlrg: 87.5em, xxlrg: 112.5em);


/* !TYPOGRAPHY */

$rootsize: 100%;
$basetypesize: 1rem; // base font size and unit
$lineheight_factor: 3;
$blh: ms($lineheight_factor,$basetypesize,$scale);  // baseline height and unit – also used as a factor in standardized padding

// Set size factors for type. Size factors are a funciton of $basetypesize, $scale and device-width determined $typesscale

$sizefactors: (base: 0, h1: 5, h2: 3, h3: 2, h4: 1, h5: 0, h6: 0);

// Scale factor for type at each breakpoint. Set to 0 to keep size consistent.

$typescale: (base: 0, xsml: 0, sml: 0, med:0, lrg: 0, xlrg: 0, xxlrg: 0);

// Load fonts
// Face definition mixins are located in a-core/libraries/15_font_definitions

@include font-calluna();
@include font-callunasans();

// Type faces are defined in 00_configuration folders, which get loaded before all other styles.

$basefont: "Calluna Sans", Tahoma, Arial, sans-serif;
$altfont: "Calluna", Georgia, "Time New Roman", serif;
$headingfont: "Calluna", Georgia, "Time New Roman", serif;
$codefont: "Andale Mono", Monaco, "Courier New", monospace;

// available base (b), heading (h) and alt (a) sizes listed

$extralightfw: 100;
$thinfw: 200;
$lightfw: 300;       // b, h
$normalfw: 400;      // b, h, a
$mediumfw: 500;
$semiboldfw: 600;    // a
$boldfw: 700;        // b, a
$extraboldfw: 800;   // h
$blackfw: 900;


// DEPRECATED: Use $basetypesize (above) instead)
$basefontsize: 16;

// Headers

$headingfw: $boldfw;

// Text Inset
// Use this as a max width for all body text.
// Styles must be applied via +text-inset()

$inset_text_width: 800px;


// DEPRECATED: Use $sizefactors instead

$h1fontsize: $basefontsize * 3;
$h2fontsize: $basefontsize * 2.25;
$h3fontsize: $basefontsize * 1.875;
$h4fontsize: $basefontsize * 1.5;
$h5fontsize: $basefontsize * 1.25;
$h6fontsize: $basefontsize * 1.125;



/* !UI STANDARDIZATION */

$ui_element_height: 60px;  // Standard height for single-line input, select and button elements.
$ui_border_radius: 2px;
$ui_border_width: 2px;
$ui_border_color: $light_neutral;
$ui_hover_border_color: $link_colour;
$ui_font_family: $basefont;
$ui_color: $darker_neutral;  // Default for icons and text-based interactive components.

$ui_padding: -4;  // Size fact
$ui_font_size: 0; // Size factor

$border_radius: $ui_border_radius;

// Buttons

$buttonfontsize: 1;

$btn_padding: $ui_padding;
$btn_border_width: $ui_border_width;
$btn_border_radius: $ui_border_radius;
$btn_min_width: 200px;
$btn_width: 280px;
$btn_max_width: 375px;

$btn_color: $cta;
$btn_bg_color: $white;
$btn_border_color: $cta;
$btn_hover_color: $black;
$btn_hover_border_color: $black;
$btn_hover_bg_color: $white;

$btn_submit_color: $white;
$btn_submit_bg_color: $impact;
$btn_submit_border_color: $impact;
$btn_submit_hover_color: $white;
$btn_submit_hover_border_color: darken($impact,10%);
$btn_submit_hover_bg_color: darken($impact,10%);

$outline_btn_color: $mid_neutral;
$outline_btn_hover_color: $ui_hover_border_color;


/* !IMAGE PATHS */

// Handy shortcuts for sourcing images

$imagepath: "../img";
$iconpath: "#{$imagepath}/icons";
$markpath: "#{$imagepath}/marks";
$triangle: "#{$iconpath}/triangle.svg";



/* Ratios from Modular Scale for reference. Use selected value as scale above. */
  // Ratios
//double-octave    : 4
//pi               : 3.14159265359
//major-twelfth    : 3
//major-eleventh   : 2.666666667
//major-tenth      : 2.5
//octave           : 2
//major-seventh    : 1.875
//minor-seventh    : 1.777777778
//major-sixth      : 1.666666667
//phi              : 1.618034
//golden           : $phi
//minor-sixth      : 1.6
//fifth            : 1.5
//augmented-fourth : 1.41421
//fourth           : 1.333333333
//major-third      : 1.25
//minor-third      : 1.2
//major-second     : 1.125
//minor-second     : 1.066666667       */


