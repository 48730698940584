@mixin flex-sidebar($width: $standard_sidebar_width) {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;

  @include bp(med) {
    flex-direction: row; }

  > aside {

    @include bp(med) {
      flex-shrink: 0;
      width: $width; } }

  > div {
    flex-grow: 1; } }
