%splash-element-full-height {

  @include bp(lrg) {
    min-height: 800px;
    height: 100vh;
    max-height: 1000px; } }

.view-id-homepage_splash {
  width: 100vw;
  @extend %splash-element-full-height;

  .view-content {
    width: 100%;
    height: 100%;
    position: relative;

    > div {
      flex-direction: column-reverse;
      width: 100vw;
      opacity: 0;
      display: none;

      &:first-child {
        display: flex;
        opacity: 1; }

      &:nth-child(odd) {
        > * {
          background-color: set-colour(2,'watermark'); } }

      &:nth-child(even) {
        @include bp(med) {
          flex-direction: row-reverse; }

        > * {
          background-color: set-color(4,'watermark'); } }


      @include bp(lrg) {
        height: 100%;
        flex-direction: row;
        justify-content: stretch;
        position: absolute;
        top: 0;
        left: 0; }

      > * {   // Image and Menu-Info

        width: 100%;

        @include bp(lrg) {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: stretch;
          padding-bottom: 0;

          header {  // Menu
            flex-grow: 0; }

          > div {  // Information
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center; } }


        &:last-child {
          @include bp(lrg) {
            @include set-padding(0,4); } } } } }

  #block-system-main-menu .block_content {
    > ul.menu {

      > li {
        @include set-left-margin(-8);
        @include set-right-margin(-8);

        > ul.menu {
          display: none; // no secondary menu on small screens

          @include bp(lrg) {
            display: flex; } } }

      &:after {
        display: none;

        @include bp(lrg) {
          display: flex; } } } }


  figure {
    @extend %splash-element-full-height;

    @include bp(lrg) {
      width: 100%;

      a {
        display: block;
        height: 100%;
        width: 100%; }

      img {
        object-fit: cover;
        height: 100%; } }

    &:hover {
      figcaption {
        display: none; } } } // At client request


  .logo {
    max-width: 400px;
    margin: 0 auto; }


  .info {

    @include set-bottom-padding(2);

    @include bp(lrg) {
      padding-bottom: 0; }


    h2 {
      font-weight: $semiboldfw;
      @include font-size(6);
      text-align: center;
      margin: 0 0 rv(0) 0;

      a {
        color: set-colour(1);

        &:active, &:hover {
          color: set-colour(1,'shade'); } } }


    .field-name-field-subtitle {

      .field-item {
        @include font-size(3);
        color: $black; } }

    .field-name-field-deck {
      @include set-top-margin(2);
      @include set-bottom-padding(-2);

      width: 70%;

      @include bp(lrg) {
        width: 90%; } }

    .cta {
      text-align: center;
      text-transform: uppercase;
      @include font-size(1);

      a {
        text-decoration: underline;
        color: set-color(1);
        font-weight: $boldfw;

        &:hover, &:active {
          color: set-colour(1,'shade'); } } } } }

// For .actions see ./30_elements/_page_actions
