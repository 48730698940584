.actions {
  text-align: center;
  @include set-top-margin(5);

  a {
    display: inline-block;
    @include set-right-margin(-2);
    @include set-top-padding(-4);
    width: 100px;
    position: relative;
    overflow: visible;

    &:last-child {
      margin-right: 0; }

    &:hover, &:active {
      color: set-colour(1); }

    &:before {
      content: '';
      position: absolute;
      display: block;
      z-index: 1;
      width: 100%;
      top: -30px;
      height: 30px;
      background-repeat: no-repeat;
      background-position: center center; } }


  .documents {
    &:before {
      background-image: url("#{$iconpath}/document.svg"); }

    &:hover, &:active {
      &:before {
        background-image: url("#{$iconpath}/document-brick.svg"); } } }

  .people {
    &:before {
      background-image: url("#{$iconpath}/profile.svg"); }

    &:hover, &:active {
      &:before {
        background-image: url("#{$iconpath}/profile-brick.svg"); } } }

  .events {
    &:before {
      background-image: url("#{$iconpath}/event.svg"); }

    &:hover, &:active {
      &:before {
        background-image: url("#{$iconpath}/event-brick.svg"); } } }

  .places {
    &:before {
      background-image: url("#{$iconpath}/pin.svg"); }

    &:hover, &:active {
      &:before {
        background-image: url("#{$iconpath}/pin-brick.svg"); } } } }
