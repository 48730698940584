.node-page.node-document {
  // see ./c_local/30_elements/fieldset.sass
  // fieldset legends have a predefined accompanying icon illustration

  .group-doc-basic {
    legend:before {
      display: block; } }

  .group-doc-actors, .group-persons-mentioned {
    legend:before {
      display: block;
      background-image: url("#{$iconpath}/profile-rev.svg");
      background-size: rv(-4); } }

  .group-places-mentioned {
    legend:before {
      display: block;
      background-image: url("#{$iconpath}/compass-rev.svg");
      background-size: rv(-2); } } }
