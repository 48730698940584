.pager {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .pager-item a, .pager-current {
      @extend %paginator-circle-link; }

  .pager-current {
    background-color: $impact; } }


.item-list {
  .pager {
    @include set-top-margin(0);
    @include set-bottom-margin(0);

    li {
      line-height: 0;
      padding: 0; } } }
