// @file _slick_dots.sass
// @package Slick Slider http://kenwheeler.github.io/slick/
// @description A modified version of the slider dot navigation based on the slick theme.

.slick-dots {
  position: relative;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;

  li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    cursor: pointer;

    button {
      position: relative;
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      min-width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      cursor: pointer;

      &:hover {
        outline: none;
        background: transparent;

        &:before {
          opacity: 1; } }


      &:before {
        position: absolute;
        top: -12.5px;
        left: 0;
        content: "\2022";
        width: 20px;
        height: 20px;
        font-family: $altfont;
        @include font-size(3);
        @include line-height(3);
        text-align: center;
        color: $dark_neutral;
        opacity: 0.3;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; } }


    &.slick-active button:before {
      color: $black;
      opacity: 1; } } }


