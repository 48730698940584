.pager {
  .pager-next, .pager-next-inactive {
    img {
      transform: rotate(180deg); } }

  img {
    width: 30px; }

  .pagecount {
    min-width: 100px;
    text-transform: uppercase; } }
