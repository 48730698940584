figure {
  margin: 0;
  box-sizing: border-box; }

figcaption {
  display: block;
  box-sizing: border-box;
  font-weight: normal;
  @include font-size(0);
  font-style: italic;
  color: $black;

  h4 {
    font-weight: $normalfw; } }
