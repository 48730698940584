// Standardized handler for creating legible line lengths

@mixin inset-text($factor: null, $increment: 1,$ratio:$scale) {
  max-width: $inset_text_width;
  margin-left: auto;
  margin-right: auto;

  @if $factor != null {
    @include spacing('padding-left',$factor,$increment,$ratio);
    @include spacing('padding-right',$factor,$increment,$ratio); } }

@mixin full-width-text($horizontalpadding: null) {
  max-width: $max_width;
  margin-left: auto;
  margin-right: auto;

  @if $horizontalpadding != null {
    @include set-left-padding($horizontalpadding);
    @include set-right-padding($horizontalpadding); }
  @else {
    padding-left: 0;
    padding-right: 0; } }
