/* !BASIC STRUCTURE */

html {
  scroll-behaviour: smooth; }

html, body {
  height: 100%; }

body {
  padding: 0;
  margin: 0;
  background-color: $bgcolor; }

section {
  overflow: hidden; }

div, aside, nav, article, section, header, footer {
  box-sizing: border-box; }
