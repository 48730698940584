// HEADINGS ------------------------------

h1 {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: rv(3) * -1;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: rv(2);
    width: rv(2);
    background-repeat: no-repeat;
    background-image: url("#{$iconpath}/document-rev.svg");
    background-position: center center;
    background-size: rv(-2); // set for individual icon
    background-color: set-colour(1);
    border-radius: rv(2);
    display: none; } } // Icons are set in ./50_contexts/_heading_icon_contexts.sass

.surtitle + h1 {
  &:before {
    top: rv(5) * -1; } }

h4 {
  text-transform: uppercase;
  color: set-colour(1);
  font-family: $basefont; }
