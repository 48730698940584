fieldset {
  border-style: solid;
  border-width: 1px 0px;
  border-color: $dark_neutral;
  padding: rv(-3) 0;
  margin: 0;
  height: min-content;
  position: relative;
  min-height: rv(-3) * 2;

  legend {
    float: left;
    position: relative;

    & + .fieldset-wrapper {
      clear: left; }

    &:before {
      @extend %icon-side;
      background-image: url("#{$iconpath}/info-alt-rev.svg");
      display: none; } } }


fieldset + fieldset, div.node > fieldset + fieldset {
  margin-top: 0;
  border-top: 0; }

.field-grid {
  .fieldset-wrapper {
    @extend %local-grid-five; } }

legend {
  width: 100%;
  box-sizing: border-box;
  font-weight: $boldfw;
  text-transform: uppercase;
  color: set-colour(1);
  padding: 0;

  a {
    display: block;
    width: inherit;
    color: set-colour(1); }

  .summary {
    display: block;
    color: $neutral;
    font-weight: $normalfw;
    text-transform: initial; } }


html.js {
  fieldset {
    &.collapsible {
      .fieldset-legend {
        background: url(#{$iconpath}/triangle-down-neutral.svg) right no-repeat;
        background-size: rv(-5);
        padding-left: 0;
        display: block;
        width: 100%;
        height: min-content; } }


    &.collapsed {
      height: min-content;
      border-bottom: 1px solid $dark_neutral; }

    &.collapsible.collapsed {
      .fieldset-legend {
        background-image: url(#{$iconpath}/triangle-neutral.svg);
        height: min-content;

        &:hover {
          background-image: url(#{$iconpath}/triangle-down-neutral.svg); } } } } }
