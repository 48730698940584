// Spacing-related mixins
// $factor is an integer supplied to the modularscale
// NB: Remember that a $factor of 0 will return the set baseline height value ($blh from the configuration file). Set spacing of 0 expliticly (e.g. padding: 0) as there is no way to set a zero padding via these mixins.


// Scale factor spacing
// $increment sets different sizes for different device widths. Set to 0 for consistent widths.
// $limit ensures that the bottom and top end are limited to a single $increment to prevent extreme spacing (good for typography)

@mixin spacing($property: padding,$factor: 0,$increment: 0,$limit: false,$ratio:$scale) {
  @if $increment == 0 {
    #{$property}: ms($factor,$blh,$ratio); }
  @else {
    @if $limit {
      #{$property}: ms($factor - $increment,$blh,$ratio); }
    @else {
      #{$property}: ms($factor - (2 * $increment),$blh,$ratio); }
    @include bp(sml) {
      #{$property}: ms($factor - $increment,$blh,$ratio); }
    @include bp(med) {
      #{$property}: ms($factor, $blh, $ratio); }
    @include bp(lrg) {
      @if $limit {
        #{$property}: ms($factor + $increment,$blh,$ratio); }
      @else {
        #{$property}: ms($factor + (2 * $increment),$blh,$ratio); } }
    @include bp(xlrg) {
      @if $limit {
        #{$property}: ms($factor + $increment,$blh,$ratio); }
      @else {
        #{$property}: ms($factor + (3 * $increment),$blh,$ratio); } } } }

// !SET PADDING AND MARGIN SYNTAX
// Use for scale factor spacing on specific properties

@mixin set-padding($factor: 0, $increment: 0, $ratio: $scale) {
  @include spacing('padding',$factor,$increment,$ratio); }

@mixin set-top-padding($factor:0, $increment: 0, $ratio: $scale) {
  @include spacing('padding-top', $factor, $increment,$ratio); }

@mixin set-bottom-padding($factor:0, $increment: 0, $ratio: $scale) {
  @include spacing('padding-bottom',$factor, $increment, $ratio); }

@mixin set-left-padding($factor:0, $increment: 0, $ratio: $scale) {
  @include spacing('padding-left', $factor, $increment,$ratio); }

@mixin set-right-padding($factor:0, $increment: 0, $ratio: $scale) {
  @include spacing('padding-right',$factor, $increment, $ratio); }

@mixin set-margin($factor:0, $increment: 0, $ratio: $scale) {
  @include spacing('margin',$factor, $increment, $ratio); }

@mixin set-top-margin($factor:0, $increment: 0, $ratio: $scale) {
  @include spacing('margin-top',$factor, $increment, $ratio); }

@mixin set-bottom-margin($factor:0, $increment: 0, $ratio: $scale) {
  @include spacing('margin-bottom',$factor, $increment, $ratio); }

@mixin set-left-margin($factor:0, $increment: 0, $ratio: $scale) {
  @include spacing('margin-left',$factor, $increment, $ratio); }

@mixin set-right-margin($factor:0, $increment: 0, $ratio: $scale) {
  @include spacing('margin-right',$factor, $increment, $ratio); }

// !ALTERNATIVE SET SYNTAX
// Because it keeps happening.


@mixin set-padding-top($factor:0, $increment: 0, $ratio: $scale) {
  @include spacing('padding-top', $factor, $increment,$ratio); }

@mixin set-padding-bottom($factor:0, $increment: 0, $ratio: $scale) {
  @include spacing('padding-bottom',$factor, $increment, $ratio); }

@mixin set-padding-left($factor:0, $increment: 0, $ratio: $scale) {
  @include spacing('padding-left', $factor, $increment,$ratio); }

@mixin set-padding-right($factor:0, $increment: 0, $ratio: $scale) {
  @include spacing('padding-right',$factor, $increment, $ratio); }

@mixin set-margin-top($factor:0, $increment: 0, $ratio: $scale) {
  @include spacing('margin-top',$factor, $increment, $ratio); }

@mixin set-margin-bottom($factor:0, $increment: 0, $ratio: $scale) {
  @include spacing('margin-bottom',$factor, $increment, $ratio); }

@mixin set-margin-left($factor:0, $increment: 0, $ratio: $scale) {
  @include spacing('margin-left',$factor, $increment, $ratio); }

@mixin set-margin-right($factor:0, $increment: 0, $ratio: $scale) {
  @include spacing('margin-right',$factor, $increment, $ratio); }


// Ignores device width and baselineheight
// Returns a factor of the base typesize

@mixin set-padding-fixed($factor,$ratio:$scale) {
  padding: ms($factor,$basetypesize,$ratio); }



// !STANDARDIZED SPACING
// Use to regulate spacing across elements

@mixin standard-line-height() {
  @include spacing('line-height',map-get($sizefactors,'base'),0,true,$scale); }

// Standard single margins

@mixin standard-top-margin() {
  @include spacing('margin-top',0,1,$scale); }

@mixin standard-bottom-margin() {
  @include spacing('margin-bottom',0,1,$scale); }

@mixin standard-left-margin() {
  @include spacing('margin-left',0,1,$scale); }

@mixin standard-right-margin() {
  @include spacing('margin-right',0,1,$scale); }

// Even horizontal and vertical margins
// You my provide an optional spacing factor for the other axis.

@mixin standard-vertical-margin($horizontal: null,$factor: 1) {
  @include standard-top-margin();
  @include standard-bottom-margin();
  @if $horizontal != null {
    @include spacing('margin-left',$factor,$increment,$ratio);
    @include spacing('margin-right',$factor,$increment,$ratio); } }

@mixin standard-horizontal-margin($vertical: null) {
  @include standard-left-margin();
  @include standard-right-margin();
  @if $vertical != null {
    @include spacing('margin-top',$factor,$increment,$ratio, $factor: 1);
    @include spacing('margin-bottom',$factor,$increment,$ratio); } }
