// Standardized handler for reponsive margins

@mixin responsive-inset($factor: 0, $increment: 1,$ratio:$scale) {
  margin: 0 auto;
  @if $factor != null {
    @include spacing('padding-left',$factor,$increment,$ratio);
    @include spacing('padding-right',$factor,$increment,$ratio); }

  @include bp(med) {
    max-width: $max_width; } }
