@keyframes build {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes build-08 {
  from {
    opacity: 0; }
  to {
    opacity: 0.8; } }

@keyframes build-05 {
  from {
    opacity: 0; }
  to {
    opacity: 0.5; } }
