/* Assigns a header icon to various contexts. The icon is defined in ./30_elements/_heading_collection. */

@mixin show_page_icon($icon) {
  h1:before {
    display: block;
    background-image: url("#{$iconpath}/#{$icon}"); } }

// Define contexts by icon

$document: ('body.node-type-document','.page-documents','.page-node-viewer');
$event: ('body.node-type-event','.page-events','.page-events-timeline');
$place: ('.page-map-of-1795-locations','.page-places','body.vocabulary-geography','body.node-type-place');
$profile: ('body.node-type-person','.page-people');


@each $selector in $document {
  #{$selector} {
    @include show_page_icon('document-rev.svg'); } }

@each $selector in $event {
  #{$selector} {
    @include show_page_icon('event-rev.svg'); } }

@each $selector in $profile {
  #{$selector} {
    @include show_page_icon('profile-rev.svg'); } }

@each $selector in $place {
  #{$selector} {
    @include show_page_icon('pin-rev.svg'); } }
