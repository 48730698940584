%icon-side {
  content: '';
  position: absolute;
  height: rv(0);
  width: rv(0);
  display: block;
  background-repeat: no-repeat;
  background-image: url("#{$iconpath}/document-rev.svg");
  background-position: center center;
  background-size: rv(-9); // set for individual icon
  background-color: set-colour(1);
  border-radius: rv(0)/2;
  top: 0;
  left: rv(3) * -1; }


%icon-inline {
  &:before {}
  content: '';
  position: relative;
  height: rv(0);
  width: rv(0);
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url("#{$iconpath}/document-rev.svg");
  background-position: center center;
  background-size: rv(-6); // set for individual icon
  background-color: $dark_neutral;
  border-radius: rv(0)/2;
  @include set-right-margin(-2);
  float: left; }
