// Specialized fieldset on Document pages

.docnav {
  border: 1px solid $neutral;
  color: $neutral;
  @include set-padding(-2);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-image: url("#{$iconpath}/document-neutral.svg");
  background-position: center center;
  background-size: rv(0);

  a {
    color: $dark_neutral; }

  > * {
    width: 40%; } }
