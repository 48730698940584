/**
 * @file
 * Font-face definition mixin
 * Must be accompanied by full range of web fonts in the specified location (#{$path}).
 *
 * Calluna
 *
 */

@mixin font-calluna($path:'../fonts') {

  /* calluna-light - latin */
  @font-face {
    font-family: 'Calluna';
    font-style: normal;
    font-weight: 300;
    src: url('#{$path}/calluna/calluna-light.eot');
    src: local('Calluna Light'), local('Calluna-Light'), url('#{$path}/calluna/calluna-light.eot?#iefix') format('embedded-opentype'), url('#{$path}/calluna/calluna-light.woff') format('woff'),  url('#{$path}/calluna/calluna-light.woff') format('woff'), url('#{$path}/calluna/calluna-light.ttf') format('truetype'),url('#{$path}/calluna/calluna-light.svg#Calluna') format('svg'); }

  /* calluna-lightitalic - latin */
  @font-face {
    font-family: 'Calluna';
    font-style: italic;
    font-weight: 300;
    src: url('#{$path}/calluna/calluna-lightitalic.eot');
    src: local('Calluna Light Italic'), local('Calluna-Light-Italic'), url('#{$path}/calluna/calluna-lightitalic.eot?#iefix') format('embedded-opentype'), url('#{$path}/calluna/calluna-lightitalic.woff') format('woff'),  url('#{$path}/calluna/calluna-lightitalic.woff') format('woff'), url('#{$path}/calluna/calluna-lightitalic.ttf') format('truetype'),url('#{$path}/calluna/calluna-lightitalic.svg#Calluna') format('svg'); }

  /* calluna-regular - latin */
  @font-face {
    font-family: 'Calluna';
    font-style: normal;
    font-weight: 400;
    src: url('#{$path}/calluna/calluna-regular.eot');
    src: local('Calluna Regular'), local('Calluna-Regular'), url('#{$path}/calluna/calluna-regular.eot?#iefix') format('embedded-opentype'), url('#{$path}/calluna/calluna-regular.woff') format('woff'),  url('#{$path}/calluna/calluna-regular.woff') format('woff'), url('#{$path}/calluna/calluna-regular.ttf') format('truetype'),url('#{$path}/calluna/calluna-regular.svg#Calluna') format('svg'); }

  /* calluna-italic - latin */
  @font-face {
    font-family: 'Calluna';
    font-style: italic;
    font-weight: 400;
    src: url('#{$path}/calluna/calluna-italic.eot');
    src: local('Calluna Italic'), local('Calluna-Italic'), url('#{$path}/calluna/calluna-italic.eot?#iefix') format('embedded-opentype'), url('#{$path}/calluna/calluna-italic.woff') format('woff'),  url('#{$path}/calluna/calluna-italic.woff') format('woff'), url('#{$path}/calluna/calluna-italic.ttf') format('truetype'),url('#{$path}/calluna/calluna-italic.svg#Calluna') format('svg'); }

  /* calluna-semibold - latin */
  @font-face {
    font-family: 'Calluna';
    font-style: normal;
    font-weight: 600;
    src: url('#{$path}/calluna/calluna-semibold.eot');
    src: local('Calluna SemiBold'), local('Calluna-SemiBold'), url('#{$path}/calluna/calluna-semibold.eot?#iefix') format('embedded-opentype'), url('#{$path}/calluna/calluna-semibold.woff') format('woff'),  url('#{$path}/calluna/calluna-semibold.woff') format('woff'), url('#{$path}/calluna/calluna-semibold.ttf') format('truetype'),url('#{$path}/calluna/calluna-semibold.svg#Calluna') format('svg'); }

  /* calluna-semibolditalic - latin */
  @font-face {
    font-family: 'Calluna';
    font-style: italic;
    font-weight: 600;
    src: url('#{$path}/calluna/calluna-semibolditalic.eot');
    src: local('Calluna SemiBold Italic'), local('Calluna-SemiBoldItalic'), url('#{$path}/calluna/calluna-semibolditalic.eot?#iefix') format('embedded-opentype'), url('#{$path}/calluna/calluna-semibolditalic.woff') format('woff'),  url('#{$path}/calluna/calluna-semibolditalic.woff') format('woff'), url('#{$path}/calluna/calluna-semibolditalic.ttf') format('truetype'),url('#{$path}/calluna/calluna-semibolditalic.svg#Calluna') format('svg'); }

  /* calluna-bold - latin */
  @font-face {
    font-family: 'Calluna';
    font-style: normal;
    font-weight: 800;
    src: url('#{$path}/calluna/calluna-bold.eot');
    src: local('Calluna Bold'), local('Calluna-Bold'), url('#{$path}/calluna/calluna-bold.eot?#iefix') format('embedded-opentype'), url('#{$path}/calluna/calluna-bold.woff') format('woff'),  url('#{$path}/calluna/calluna-bold.woff') format('woff'), url('#{$path}/calluna/calluna-bold.ttf') format('truetype'),url('#{$path}/calluna/calluna-bold.svg#Calluna') format('svg'); }

  /* calluna-bolditalic - latin */
  @font-face {
    font-family: 'Calluna';
    font-style: italic;
    font-weight: 800;
    src: url('#{$path}/calluna/calluna-bolditalic.eot');
    src: local('Calluna Bold Italic'), local('Calluna-Bold'), url('#{$path}/calluna/calluna-bolditalic.eot?#iefix') format('embedded-opentype'), url('#{$path}/calluna/calluna-bolditalic.woff') format('woff'),  url('#{$path}/calluna/calluna-bolditalic.woff') format('woff'), url('#{$path}/calluna/calluna-bolditalic.ttf') format('truetype'),url('#{$path}/calluna/calluna-bolditalic.svg#Calluna') format('svg'); }


  /* calluna-black - latin */
  @font-face {
    font-family: 'Calluna';
    font-style: normal;
    font-weight: 900;
    src: url('#{$path}/calluna/calluna-black.eot');
    src: local('Calluna Bold'), local('Calluna-Bold'), url('#{$path}/calluna/calluna-black.eot?#iefix') format('embedded-opentype'), url('#{$path}/calluna/calluna-black.woff') format('woff'),  url('#{$path}/calluna/calluna-black.woff') format('woff'), url('#{$path}/calluna/calluna-black.ttf') format('truetype'),url('#{$path}/calluna/calluna-black.svg#Calluna') format('svg'); }

  /* calluna-blackitalic - latin */
  @font-face {
    font-family: 'Calluna';
    font-style: italic;
    font-weight: 900;
    src: url('#{$path}/calluna/calluna-blackitalic.eot');
    src: local('Calluna Bold Italic'), local('Calluna-Bold'), url('#{$path}/calluna/calluna-blackitalic.eot?#iefix') format('embedded-opentype'), url('#{$path}/calluna/calluna-blackitalic.woff') format('woff'),  url('#{$path}/calluna/calluna-blackitalic.woff') format('woff'), url('#{$path}/calluna/calluna-blackitalic.ttf') format('truetype'),url('#{$path}/calluna/calluna-blackitalic.svg#Calluna') format('svg'); } }

