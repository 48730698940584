%body-text {
  @extend %body-text;
  font-family: $altfont; }

%inset-body-text {
  @include inset-text(); }

%surtitle {
  text-align: center;
  margin: 0;
  color: set-colour(1);
  @include font-size(-1);
  font-weight: $boldfw;
  text-transform: uppercase; }
