// HEADINGS ------------------------------

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  color: $black;
  font-family: $headingfont;
  font-weight: $headingfw; }

h1,h2,h3 {
  width: 100%; }

h1, .h1 {
  @include font-size(map-get($sizefactors,'h1'));
  @include line-height(map-get($sizefactors,'h1') - 1);
  @include set-top-margin(1);
  @include set-bottom-margin(0,0); }

h2, .h2 {
  @include font-size(map-get($sizefactors,'h2'));
  @include line-height(map-get($sizefactors,'h2') - 1);
  @include set-top-margin(1,0);
  @include set-bottom-margin(-1,0); }

h3, .h3, %h3 {
  @include font-size(map-get($sizefactors,'h3'));
  @include line-height(map-get($sizefactors,'h3') - 1);
  @include standard-top-margin();
  margin-bottom: 0;
  text-transform: uppercase; }

h4, .h4 {
  @include font-size(map-get($sizefactors,'h4'));
  @include line-height(1);
  @include standard-top-margin();
  margin-bottom: 0; }

h5, .h5 {
  @include font-size(map-get($sizefactors,'h5'));
  @include line-height(1);
  @include set-top-margin(0,0);
  font-weight: $normalfw;
  font-style: italic;
  margin-bottom: 0; }

h6, .h6 {
  @include font-size(map-get($sizefactors,'h6'));
  @include line-height(1);
  @include set-top-margin(0,0);
  margin-bottom: 0;
  font-weight: $normalfw;
  color: $dark_neutral; }
