@keyframes pulse {
  0% {
    opacity: 0.5;
    transform: scale(1, 1); }
  50% {
    opacity: 1;
    transform: scale(1.1, 1.1); }
  100% {
    opacity: 0.5;
    transform: scale(1, 1); } }
