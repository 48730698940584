%data-table {
  width: 100%;

  tbody {
    border-width: 0px; }

  thead {
    border: 0;

    tr {
      border-top-width: 0px;
      border-bottom-width: 0px; } }

  tr, tr.even, tr.odd {
    background-color: transparent;
    border-width: 1px 0px;
    border-color: $light_neutral;
    border-style: solid; }

  th, td {
    margin: 0;
    @include set-padding(-4,1);
    @include font-size(0); }

  th {
    text-transform: uppercase;
    font-weight: $boldfw;
    border-bottom: 0; } }

%data-table-zebra {
  background-color: $lightest_neutral;

  tr:nth-child(2n) {
    background-color: $light_neutral; } }
